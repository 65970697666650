









import Vue from "vue";

export default Vue.extend({
  name: "Breadcrumb",
  props: {
    name: {
      type: String,
      default: ""
    }
  },
});
